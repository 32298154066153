@import "../../../variables";

.SphereModal {
  .ant-modal-content {
    border-radius: 8px !important;
  }

  &_Content {
    display: flex;
    flex-direction: column;
  }

  &_TabsContainer {
    margin: 0px 6px;
  }

  &_Tabs {
    margin: 0 6px;
    row-gap: 6px;

    .ant-tabs-nav {
      margin: 0 8px;
    }

    .ant-tabs-nav:before {
      border-bottom: none !important;
    }
  }

  &_Column {
    width: 90%;

    &_Team {
      display: grid;
      grid-template-columns: 16px auto 240px;
      align-items: center;
      gap: 10px;
      margin-bottom: 8px;

      p {
        margin: 0;
      }
    }
  }

  .ant-input:focus {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }

  &_TitleContainer {
    margin: 8px 48px 8px 8px;
    display: flex;
    align-items: center;
    gap: 8px
  }

  &_Title, &_TitleInput {
    font-size: 14pt;
    font-family: $main-font;
    font-weight: bold;
    color: $dark-blue;
    margin-left: 8px;
    padding: 0 4px;
  }

  &_TitleInput {
    width: 300px;
  }

  &_EditIcon {
    font-size: 16pt;
    cursor: pointer;
    color: $dark-blue;

    &:hover {
      opacity: 0.7;
      transition: opacity 300ms;
    }
  }

  &_SaveButton, &_SaveButton--disabled, &_CancelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    max-width: 80px;
  }

  &_SaveButton {
    background-color: $middle-blue !important;
    color: $white !important;

    &:hover,
    &:focus {
      border-color: $primary-blue !important;
      background-color: $primary-blue !important;
    }
  }


  &_SharingContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 8px 10px 8px;
    row-gap: 24px;
  }

  &_ShareHeader {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;

    h5 {
      margin: 0 !important;
    }
  }

  &_PersonalShare {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    &_UserInput {
      width: 100%;
      display: flex;
      row-gap: 10px;
      flex-direction: column;
    }

    &_EmailContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      column-gap: 10px;
      cursor: pointer;

      &_Icon {
        color: $primary-red;
      }

      &_Text {
        transition: color 300ms;

        &:hover {
          color: $light-blue;
        }
      }
    }

    &_AccessSelect {
      width: 100%;
    }
  }

  &_ShareWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 12px;
  }

  &_GlobalShare {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    margin-bottom: 24px;
    margin-top: 10px;

    &_LinkInput {
      width: 100%;
    }

    &_AccessSelect {
      width: 100%;
    }

    &_Select {
      width: 100%;
    }
  }

  &_Select {
    width: 100%;
  }

  &_SubmitButton {
    border-radius: 8px !important;
    background-color: $middle-blue !important;
    font-size: 14px;
    font-weight: bold;
    color: $white !important;

    &:hover,
    &:focus {
      border-color: $primary-blue !important;
      background-color: $primary-blue !important;
    }
  }

  &_FooterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    column-gap: 10px;
  }

  &_DeleteButton {
    font-size: 14px;
    font-family: $main-font;
    color: $light-red !important;
    transition: color 200ms;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 300ms;
    }
  }

  &_CreateButton {
    border-radius: 6px;
    padding: 6px 0;
    display: flex;
    justify-content: center;
    flex: 1;
    transition: background-color 150ms;
    cursor: pointer;
    background-color: $lighter-green;
    color: $primary-white;

    &:hover {
      background-color: $light-green;
    }
  }
}

.selectedEmail {
  font-weight: bold;
  color: $primary-blue;
}

.blurred {
  filter: blur(2px);
}

.invalid {
  font-weight: bold;
  border: 1px solid $light-red;
  color: $light-red;

  &:hover {
    border-color: $light-red !important;
  }

  &:focus {
    border-color: $light-red !important;
  }
}
