.whiz-container {
  height: calc(100vh - 35px);
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  pointer-events: auto;

  .connection-status-bar {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    
    .connection-indicator {
      padding: 4px 12px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 500;
      cursor: help;
      
      &.connected {
        background-color: #e6f7e6;
        color: #2e7d32;
      }
      
      &.disconnected {
        background-color: #ffebee;
        color: #c62828;
      }
      
      &.initializing {
        background-color: #fff8e1;
        color: #f57f17;
      }
      
      &.failed {
        background-color: #ffebee;
        color: #c62828;
        font-weight: bold;
      }
    }
  }

  .messages-container {
    flex: 1;
    overflow-y: auto;
    position: relative;
    background: white;
  }

  .message {
    margin-bottom: 16px;
    display: flex;
    position: relative;

    &.user {
      justify-content: flex-end;

      .message-content {
        background: #007bff;
        color: white;

        pre, code {
          background: rgba(255, 255, 255, 0.1);
          color: white;
        }

        a {
          color: white;
          text-decoration: underline;
        }

        // Override syntax highlighter background for user messages
        div[class*="language-"] {
          background: transparent !important;
          padding: 0 !important;
          margin: 0.5em 0 !important;
          border-radius: 4px;
          
          code {
            background: rgba(255, 255, 255, 0.1) !important;
            color: white !important;
          }
        }
      }
    }

    &.assistant {
      justify-content: flex-start;

      .message-content {
        background: #f8f9fa;
        color: #333;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        border: 1px solid #e9ecef;

        pre, code {
          background: #fff;
          color: #333;
        }

        a {
          color: #007bff;
        }

        // Style syntax highlighter for assistant messages
        div[class*="language-"] {
          background: #fff !important;
          padding: 0.75em !important;
          margin: 0.5em 0 !important;
          border-radius: 4px;
          border: 1px solid #e9ecef;
          
          code {
            background: transparent !important;
            color: inherit !important;
          }
        }
      }
    }

    .message-content {
      max-width: 70%;
      padding: 12px 16px;
      border-radius: 12px;
      position: relative;

      // Markdown styles
      p {
        margin: 0 0 1em;
        &:last-child {
          margin-bottom: 0;
        }
      }

      h1, h2, h3, h4, h5, h6 {
        margin: 1em 0 0.5em;
        &:first-child {
          margin-top: 0;
        }
      }

      ul, ol {
        margin: 0.5em 0;
        padding-left: 1.5em;
      }

      li {
        margin: 0.25em 0;
      }

      pre {
        margin: 0.5em 0;
        padding: 0.75em;
        border-radius: 6px;
        overflow-x: auto;
      }

      code {
        padding: 0.2em 0.4em;
        border-radius: 3px;
        font-family: monospace;
      }

      blockquote {
        margin: 0.5em 0;
        padding-left: 1em;
        border-left: 3px solid #ddd;
        color: #666;
      }

      table {
        border-collapse: collapse;
        margin: 0.5em 0;
        width: 100%;

        th, td {
          border: 1px solid #ddd;
          padding: 0.5em;
          text-align: left;
        }

        th {
          background: #f8f9fa;
        }
      }

      img {
        max-width: 100%;
        height: auto;
        margin: 0.5em 0;
      }

      // Override syntax highlighter styles
      div[class*="language-"] {
        margin: 0 !important;
        padding: 0 !important;
        background: transparent !important;
        
        pre {
          margin: 0 !important;
          padding: 0 !important;
          background: transparent !important;
        }
        
        code {
          font-family: 'Fira Code', monospace !important;
          font-size: 0.9em !important;
          line-height: 1.5 !important;
        }
      }
    }

    .typing {
      display: flex;
      align-items: center;
      gap: 12px;

      .typing-indicator {
        display: flex;
        gap: 4px;
        align-items: center;

        .dot {
          width: 6px;
          height: 6px;
          background: #007bff;
          border-radius: 50%;
          display: inline-block;
          animation: typing 1s infinite;
          opacity: 0.3;

          &:nth-child(2) { animation-delay: 0.2s; }
          &:nth-child(3) { animation-delay: 0.4s; }
        }
      }

      .typing-message {
        font-size: 14px;
        color: #666;
        font-style: italic;
        animation: fadeIn 0.3s ease;
      }
    }
  }
  .toggle-mentions-link {
    color: #999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8em;
  }

  .message-form {
    display: flex;
    align-items: center;
    padding: 1rem;
    background: white;
    border-top: 1px solid #e9ecef;
    gap: 0.5rem;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 12px 12px 0 0;
    margin: 0 1rem;

    .connection-status-icon {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      flex-shrink: 0;
      transition: background-color 0.3s ease;
      position: relative;
      cursor: help;

      &::after {
        content: attr(title);
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px 12px;
        background: #333;
        color: white;
        border-radius: 6px;
        font-size: 12px;
        white-space: pre-wrap;
        min-width: 200px;
        max-width: 300px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;
        z-index: 1000;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        pointer-events: none;
      }

      &:hover::after {
        opacity: 1;
        visibility: visible;
        bottom: calc(100% + 8px);
      }

      &.connected {
        background-color: #4CAF50;
      }

      &.disconnected {
        background-color: #FFC107;
      }

      &.initializing {
        background-color: #2196F3;
        animation: pulse 1.5s infinite;
      }

      &.failed {
        background-color: #F44336;
      }
    }

    input {
      flex: 1;
      padding: 0.75rem;
      border: 1px solid #e9ecef;
      border-radius: 4px;
      background: white;
      color: #333;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    button {
      padding: 0.75rem 1.5rem;
      background: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 1px 2px rgba(0, 123, 255, 0.2);

      &:hover:not(:disabled) {
        background: #0056b3;
        box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
  
  .error-message {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background-color: #ffebee;
    color: #c62828;
    font-size: 14px;
    border-bottom: 1px solid #ffcdd2;
    
    .warning-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #c62828;
    }
    
    span {
      flex: 1;
    }
    
    .dismiss-button {
      background: none;
      border: none;
      color: #c62828;
      font-size: 20px;
      cursor: pointer;
      padding: 0 4px;
      opacity: 0.7;
      
      &:hover {
        opacity: 1;
      }
    }
  }
  
  .loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    .loading-spinner {
      width: 40px;
      height: 40px;
      border: 4px solid #f3f3f3;
      border-top: 4px solid #007bff;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin-bottom: 16px;
    }
    
    p {
      color: #666;
      font-size: 16px;
    }
  }

  .debug-info {
    padding: 8px 16px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    color: #666;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

@keyframes typing {
  0%, 100% { 
    opacity: 0.3;
    transform: translateY(0);
  }
  50% { 
    opacity: 1;
    transform: translateY(-2px);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-4px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.mentioned-people {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.person-link {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: #e8f4ff;
  border: 1px solid #b3d4ff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  color: #0066cc;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    color: #0066cc;
    flex-shrink: 0;
  }

  span {
    // Prevent long names from breaking layout
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: #d1e9ff;
    border-color: #80bdff;
  }
}

.user-context {
  margin-top: 8px;
  padding: 8px;
  background-color: #e8f4ff;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.mentioned-data {
  margin-top: 8px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
  white-space: pre-wrap;
  word-wrap: break-word;
} 