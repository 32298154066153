.AdminPage {
  .PeopleTable {
    &_TrialDaysRemaining {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_FeatureFlags {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
  .SearchPeople {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    column-gap: 20px;
    align-items: center;
  }
}
