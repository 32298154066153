@import '../../../variables';

.RightPanel {
  width: 430px;
  font-family: $inter-font;
  background-color: #FFFFFF;
  padding: 0;
  box-shadow: -2px 0px 0px rgba(0, 0, 0, 0.05);
  min-height: 100vh;

  //flex: 0 0 auto;
  //display: flex;
  //align-items: center;
  //justify-content: space-around;
  //margin: 10px 10px 10px 0;
  //height: 75px;

  &_OpenButton, &_CloseButton {
    border: none;
    background-color: transparent;
    padding: 10px 0px 10px 10px;

    &:hover {
      color: #0E7ED8;
      transition: all .2s ease-in-out;
      cursor: pointer;
    }
  }

  &_OpenButton {
    left: 9px;
    padding: 10px 0px;
  }

  &_CloseButton {
    transform: rotate(180deg);
  }

  &_Tabs {
    height: 100vh !important;

    &--ShowInfoBar {
      height: calc(100vh - 60px) !important;
    }
  }

  .ant-tabs {
    //Line showing active tab
    &-ink-bar {
      height: 2px;
      background: #0E7ED8;
    }

    &-nav {
      margin: 10px 20px 16px 10px !important;
      flex-direction: row-reverse;
      height: 40px;

      &:before {
        border-bottom: none!important;
      }

      &-wrap {
        //Tab nav list
        .ant-tabs-nav-list {
          width: 100%;
          justify-content: space-between;
        }

        //Tab nav
        .ant-tabs-tab {
          padding: 12px 8px 15px 8px;

          //Active tab nav
          &-active {
            .ant-tabs-tab-btn {
              color: #0E7ED8 !important;
            }
          }

          //Tab nav title
          &-btn {
            color: #868686;
            font-weight: 500;
          }
        }
      }
    }

    //Tab content
    &-content-top {
      height: 100%;
    }
  }


  &_Video {
    cursor: pointer;
  }

  .video_placeholder {
    width: 100%;
    margin-top: 10px;

    &:hover {
      opacity: 0.8;
    }
  }

  .ant-steps-dot .ant-steps-item-content {
    width: auto;
  }
}
