@import "src/variables";

.BulkTeamOwnerSelector {
  margin-top: 18px;

  .TeamOwnerSelector_Trigger {
    width: 100%;
  }

  &_Team {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &_Name {
      font-size: 14px;
    //   color: $text-color;
      margin-right: 12px;
      min-width: 120px;
    }
  }
} 