@import "../../../variables";
@import "../../../scss/customAntd";

.ParticipantsTableHeader {
  font-family: $brandonGrotesque-font;
  font-weight: 420;
  font-size: 18px;
  margin: 20px 10px 15px;
}

.UncategorizedFilter {
  background-color: white;
  padding: 12px 12px 0 12px !important;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);

  &_Checkboxes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 10px;

    &_Item {
      margin: 0 0 10px 0 !important;
    }
  }
}

.ParticipantsTableWrapper {
  font-family: $inter-font;
  position: relative;

  .ant-table-selection-column {
    padding: 9px 20px 9px 24px !important;
  }

  @include my-ant-checkbox;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ParticipantsTable {
    position: relative;
    margin-bottom: 25px;

    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      z-index: 1;
      width: 100%;
      height: 15px;
      background-color: white;
      filter: blur(3px);
    }

    &_SorterIcon {
      margin-left: 2px;
    }

    //Hide antd sorter icons
    .ant-table-column-sorter {
      display: none;
    }

    .ant-table-cell-scrollbar {
      box-shadow: none;
    }

    .ant-table-container::after {
      box-shadow: none !important;
    }

    .ant-table-body {
      overflow-x: hidden !important;
      padding-bottom: 6px !important;

      &::-webkit-scrollbar-thumb {
        background-color: #b7b7b9 !important;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-track {
        background: #f5f6fb;
        margin-bottom: 6px;
      }
    }

    .ant-table-thead > tr > th {
      background-color: #f5f6fb;
      color: #000000 !important;
      font-size: 12px !important;
      line-height: 18px;
      padding: 9px 13px 9px 0;

      &:before {
        //Hide lines between columns
        display: none;
      }
    }

    &_Name {
      display: grid;

      &_FullName {
        font-weight: 600;
        color: #101828;
        word-break: break-word;
        white-space: break-spaces;
      }

      &_Email {
        font-size: 11px;
        color: #8d8d8d;
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    &_Message {
      cursor: pointer;
      display: grid;
      max-height: 200px;
      overflow-y: auto;
      max-width: 500px;

      &_Subject {
        font-weight: 600;
        color: #101828;
        font-size: 12px;
      }

      &_Body {
        color: $dark-blue;
        font-size: 13px;
        font-weight: 400;
      }

      &_Date {
        color: $light-gray;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .spheres {
      max-width: 280px !important;
      min-width: 280px !important;
      width: 280px !important;
      @media (max-width: 1200px) {
        max-width: 160px !important;
        min-width: 160px !important;
        width: 160px !important;
      }
    }

    &_ActionsColumn {
      max-width: 280px !important;
      display: grid;
      grid-template-columns: auto 1fr 82px;
      align-items: center;
      justify-content: space-between;

      &_Separator {
        display: flex;
        justify-content: center;
        color: #a1aac8;
        padding: 0 16px;
      }

      @media (max-width: 1200px) {
        max-width: 160px;
        grid-template-columns: 1fr;
        justify-items: center;

        &_Separator {
          display: none;
        }
      }
    }

    .SpheresSelector_SpheresContainer_Placeholder-container {
      display: flex;
      padding-left: 0;

      .AddSpheresButton {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: $inter-font;
        background-color: #49abfd;
        color: white;
        border: none;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500;
        padding: 8px 14px;

        svg {
          padding-left: 4px;
          width: 14px;
          height: 14px;
        }

        &:hover {
          opacity: 0.7;
          transition: opacity 0.2s linear;
        }

        &--active {
          opacity: 0.7;
        }
      }
    }

    .ActionsColumnTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_FilterButton {
        padding: 0;
        width: 18px;
        height: 18px;
        border: none;

        svg {
          background-color: #f5f6fb !important;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s linear;
        }
      }
    }

    &_ArchvieButton {
      display: flex;
      align-items: center;
      color: #a1aac8;

      span {
        margin-left: 0.3rem;
        font-weight: 500;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &_ArchiveText {
      color: #a1aac8 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    &_Row {
      &:hover > td {
        background-color: rgba(245, 246, 251, 0.6) !important;
      }

      td {
        background-color: #ffffff;
        padding: 5px 10px 5px 0;
      }
    }
  }
}

.DismissPopover {
  width: 350px;

  & .ant-popover-inner {
    background-color: $white !important;
    border-radius: 20px !important;
    box-shadow: 2px 2px 3px rgba($dark-green-blue, 0.3);

    & .ant-popover-title {
      background-color: $light-blue !important;
      border-radius: 20px 20px 0 0 !important;
    }
  }

  &_Content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    span {
      font-family: $main-font;
      color: $light-gray;
      text-align: justify !important;
    }
  }

  &_Title {
    display: flex;
    padding: 0.5rem;

    span {
      font-size: 12pt;
      font-family: $main-font;
      font-weight: bold;
      color: $white;
      text-align: center !important;
    }
  }
}

.ParticipantsTable_LoadingBox {
  margin-top: 40vh;
  display: grid;
}

.DismissColumn_FilterButton_Tooltip {
  .ant-tooltip-inner {
    width: 180px !important;
  }
}
