@import "../../../variables";

.TeamOwnerSelector {
    &_Trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 12px;
      border: 1px solid #d9d9d9;
      border-radius: 7px;
      cursor: pointer;
      transition: all 0.3s;
      background: #fff;
      min-width: 120px;
      background-color: #e4f3fe;
  
      &:hover:not(.disabled) {
        transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        box-shadow: 1px 1px 2px #94b7c0;
        background-color: #4baaf6;
      }
  
      &.disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.25);
      }
  
      &_Text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 8px;
        font-size: 12px;
        font-weight: 500;
        color: $primary-black;
        word-break: break-word;
      }
  
      &_Arrow {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  
    &_Menu {
      max-height: 300px;
      overflow-y: auto;
      background-color: #fff !important;
      border-radius: 6px !important;
      padding: 0 10px;
  
      .ant-dropdown-menu-item {
        font-family: $main-font;
        padding: 10px !important;
        border-bottom: 1px solid rgba($light-gray, 0.4) !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        color: $dark-blue !important;
  
        &:hover {
          background-color: #e4f3fe !important;
        }
      }
    }
}
