@import "../../../variables";

.Onboarding {
  display: grid;
  width: 100%;
  height: 100%;
  padding: 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  justify-content: center;
  overflow-y: auto;
  background-color: $light-gray-filler;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($dark-text, 0.2);
    border-radius: 30px;
    height: 240px;
  }

  &::-webkit-scrollbar-track {
    background: $lighter-gray;
  }

  &_Logo {
    max-width: 160px;
    margin: 16px 0 0 16px;
  }

  .ant-drawer-content {
    background-color: $primary-white;
  }

  &_StepContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }

  &_Step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    max-width: 800px;
    margin: 0 auto;
    background-color: $primary-white;
    border-radius: 8px;
    box-shadow: 0 1px 8px rgba($primary-black, 0.05);
    position: relative;

    &_Header {
      h1 {
        color: $dark-text;
        text-align: center;
        font-family: $albertSans-font;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }

    &_Body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      padding: 16px 0;
      position: relative;
      min-height: 200px;

      &_Info {
        display: flex;
        align-items: center;
        gap: 16px;

        &_Image {
          margin-bottom: 24px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            max-width: 100%;
            max-height: 100%;
          }
        }

        p {
          text-align: left !important;
        }
      }

      p {
        text-align: center;
        color: $dark-text;
        font-family: $inter-font;
        font-size: 16px;
        line-height: 1.5;
        max-width: 700px;
        margin-bottom: 12px;
      }

      &_Choices {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 20px 0;
        width: 100%;
        max-width: 600px;

        &_Item {
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid $light-gray;
          border-radius: 8px;
          padding: 10px;

          input[type="checkbox"] {
            width: 18px;
            height: 18px;
            color: $primary-white;
            accent-color: $dark-gray;
            cursor: pointer;
          }

          &--selected {
            background-color: $lightererer-gray;
          }

          label {
            color: $dark-text;
            font-family: $inter-font;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }

      &_Quote {
        color: $dark-gray;
        font-style: italic;
        text-align: right;
        max-width: 250px;
        font-size: 14px;
        bottom: -30px;
      }
    }

    button {
      height: fit-content;
      border: none;
      padding: 12px 24px;
      background-color: $primary-blue;
      color: $primary-white;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      transition: background-color 0.2s ease;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        background-color: darken($primary-blue, 5%);
      }

      &.connect-button {
        background-color: $primary-green;

        &:hover {
          background-color: darken($primary-green, 5%);
        }
      }

      &.skip-button {
        background-color: transparent;
        color: $dark-gray;
      }

      .ant-spin {
        margin-right: 8px;
      }
    }

    &_Footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      gap: 16px;

      &_Button--disabled {
        background-color: $light-gray !important;
        color: $dark-gray !important;
        cursor: not-allowed !important;
        opacity: 0.5 !important;
      }

      button.Onboarding_Step_Footer_DismissButton {
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: $dark-text;
        font-size: 14px;
        padding: 4px;
        width: auto;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  &_StepNumberInfo {
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 20px 0;

    &_Step {
      background-color: rgba($dark-text, 0.2);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }

      &--active {
        background-color: $primary-blue;
        width: 14px;
        height: 14px;
      }
    }
  }

  .loading-animation {
    width: 100%;
    height: 4px;
    background-color: $lightererer-gray;
    margin: 30px 0;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      left: -50%;
      height: 100%;
      width: 50%;
      background-color: $primary-blue;
      animation: loading 1.5s infinite ease-in-out;
    }
  }

  @keyframes loading {
    0% {
      left: -50%;
    }
    100% {
      left: 100%;
    }
  }

  .participants-table {
    width: 100%;
    margin-top: 20px;

    &-container {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
    }

    &-row {
      display: grid;
      grid-template-columns: auto 1fr auto auto;
      align-items: center;
      gap: 10px;
      padding: 10px 0;
      border-bottom: 1px solid $lighter-gray;

      &:last-child {
        border-bottom: none;
      }

      .checkbox {
        width: 18px;
        height: 18px;
      }

      .info {
        display: flex;
        flex-direction: column;

        .name {
          font-weight: 500;
          color: $dark-text;
        }

        .email {
          font-size: 14px;
          color: $dark-gray;
        }

        .preview {
          font-size: 14px;
          color: $dark-gray;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 300px;
        }
      }

      .date {
        font-size: 14px;
        color: $dark-gray;
      }

      .actions {
        display: flex;
        gap: 5px;

        button {
          padding: 5px 10px;
          font-size: 14px;
          width: auto;
          min-width: 100px;
        }

        .archive {
          background: none;
          color: $dark-gray;
          text-decoration: underline;

          &:hover {
            background: none;
            color: $dark-text;
          }
        }
      }
    }
  }

  &_ConnectedAccounts {
    width: 100%;
    max-width: 600px;
    margin: 20px 0;
    border-radius: 12px;
    border: 1px solid $lighter-gray;
    padding: 16px;

    h3 {
      margin-bottom: 12px;
      color: $dark-text;
      font-size: 18px;
      font-weight: 500;
    }

    &_List {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &_Item {
      display: flex;
      align-items: center;
      padding: 10px;
      background: $primary-white;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    }

    &_Icon {
      margin-right: 12px;
      font-size: 20px;
    }

    &_Details {
      flex: 1;
    }

    &_Email {
      font-weight: 500;
      color: $dark-text;
    }

    &_Status {
      font-size: 13px;
      margin-top: 4px;

      .status-running {
        color: $primary-green;
      }

      .status-other {
        color: $dark-gray;
      }
    }
  }

  &_ProgressContainer {
    width: 100%;
    max-width: 500px;
    margin: 20px auto 0;
    padding: 12px;
    background-color: $lighter-gray;
    border-radius: 8px;

    .ant-progress {
      margin-bottom: 8px;
    }

    p {
      color: $dark-gray;
      font-size: 14px;
      text-align: center;
      margin: 0;
    }
  }

  &_Loading {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    margin: 20px 0;
    background-color: $primary-white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    text-align: center;

    .ant-progress {
      margin-bottom: 16px;
    }

    p {
      color: $dark-text;
      font-size: 16px;
      margin: 0;
    }
  }
}
