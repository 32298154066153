@import "../variables.scss";

.RelatableModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  font-size: 16px;
  row-gap: 32px;
  font-family: $inter-font;

  &__modal {
    border-radius: 10px !important;

    .ant-modal-content {
      border-radius: 10px;
    }
  }

  &_Text {
    display: flex;
    flex-direction: column;
    row-gap: 9px;

    &_Title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }

    &_Description {
      color: $light-gray;
      font-size: 15px;
    }
  }

  &_Buttons {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    width: 100%;

    div {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding: 8px 16px;
      border-radius: 8px;
      font-weight: 500;
      transition: filter 200ms ease-in-out;

      &:hover {
        filter: brightness(1.15);
      }
    }
  }

  &_SecondaryButton {
    border: 1px solid $light-gray;
    color: $dark-green-blue;
    background-color: transparent;
  }

  &_PrimaryButton {
    background-color: $primary-blue;
    color: $white;
  }

  &_Configure {
    width: 100%;
    background-color: transparent !important;
    border: 0 !important;

    &_Panel {
      background-color: transparent !important;
      border: 0 !important;
      overflow: hidden;
    }

    .ant-collapse-header {
      padding: 0 !important;
      font-weight: 500;
    }

    .ant-collapse-content {
      border-top: none !important;
    }

    .ant-collapse-content-box {
      padding: 8px 0 !important;
    }

    &_Header {
      font-size: 14px;
    }

    &_Content {
      color: $light-gray;
      font-size: 14px;
    }
  }
}
