@import "../../../variables";

.FadedConvos {
  padding: 20px 6px;

  &_Header {
    margin-bottom: 20px;

    h3 {
      margin: 0;
      font-size: 17px;
      font-weight: 500;
      color: $dark-text;
    }
  }

  &_Table {
    .ant-table {
      background: transparent;
    }

    .ant-table-thead > tr > th {
      background: transparent;
      border-bottom: 1px solid $lighter-gray;
      color: $dark-text;
      font-weight: 500;
      padding: 14px;
    }

    .ant-table-tbody > tr > td {
      border-bottom: 1px solid $lighter-gray;
      padding: 14px;
    }

    .ant-table-tbody > tr {
      background-color: $primary-white;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: $lightererer-gray;
      }

      &.expanded {
        background-color: $lightererer-gray;
      }
    }
  }

  &_Name {
    max-width: 120px;

    &_FullName {
      display: block;
      color: $dark-text;
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 4px;
    }

    &_Email {
      display: block;
      color: $dark-gray;
      font-size: 13px;
    }
  }

  &_ActionsColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &_Separator {
      color: $light-gray;
      margin: 0 4px;
    }

    .AddSpheresButton {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
    }
  }

  &_ArchiveButton {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $dark-gray;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }

    span {
      font-size: 13px;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }

  .FadedConvos_MessageColumn {
    width: 100%;
    height: 100%;
  }

  .FadedConvos_MessageColumn_Body {
    color: $dark-gray;
    font-size: 13px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .FadedConvos_MessageColumn_Body_Text {
  }

  .FadedConvos_MessageColumns_Date {
    color: $dark-gray;
    font-size: 13px;
    line-height: 1.5;
  }

  .FadedConvos_MessageColumn_Subject {
    color: $dark-text;
    font-size: 14px;
    font-weight: 700;
  }
}

.DismissPopover {
  max-width: 300px;

  &_Title {
    font-weight: 500;
    color: $dark-text;
  }

  &_Content {
    color: $dark-gray;
    font-size: 13px;
    line-height: 1.5;
  }
}
